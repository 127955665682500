import React, { useEffect, useState } from "react";
import "./style.scss";
import InputField from "../../../components/InputField";
import { useForm } from "react-hook-form";
import Button from "components/Button";
//import { StudentService } from "services/student.service";
import { LoginService } from "services/login.service";
import { useNavigate } from 'react-router-dom'

import {
  ILoginResponseOrganization,
  ILoginErrorOrganization,
  ILoginCredentialsOrganization,
} from "../../../services/interfaces/organization.interface";
import axios from "axios";

type ILogin = {
  OrganizationName: string;
};

const Organization = () => {
  const [offers, setOffers] = useState([]) as any;
  const navigatorTo = useNavigate();

  const { register, handleSubmit }: any = useForm<ILogin>({
    mode: "onChange",
  });

 

  const onSubmit = async (data: any) => {
 
    if( data.OrganizationName === ""){
      alert("Entered your organization name=")
      return false
    }

    const result = offers.find((e: any) => e.name === data.OrganizationName);
    console.log(result, "===result");

    
   
   try {
    if (result) {
     // navigatorTo(result.api_url);
     // window.location.href = result.api_url;
     window.location.replace(result.api_url)
    } else {
      alert("Entered wrong organization name");
    }
   } catch (error) {
        alert("Entered your organization name")
   }
  };

  const getOffers = () => {
    axios
      .get("http://slbpvt.dinamalar.com/config_staging.php")
      .then((response) => {
        console.log(response, "=====> response");
        setOffers(response.data.item);
      });
  };

  console.log(offers, "offers ");

  useEffect(() => {
    getOffers();
  }, []);

  return (
    <div className="main-organization-wrp">
      <div className="Organization-wrp-title">
        <h3>Welcome to </h3>
        <h1>Smart JO</h1>
      </div>


      <div className="Organization-wrp">
        <div className="inner-organisation-wrp">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-wrp">
              <label>Please Enter Organization</label>
              <InputField
                name={"OrganizationName"}
                helpertext="OrganizationName"
                type="text"
                register={register("OrganizationName")}
              />
            </div>
            <Button type="submit">Next</Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Organization;
